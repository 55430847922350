
export const theme = {
    linkColor: '#b72236',
    linkColorHover: '#761623',
    lightGray: '#f3f6f8'
}


export const styles = {
    bmBurgerButton: {
        position: 'fixed',
        width: '24px',
        height: '20px',
        left: '22px',
        top: '22px'
    },
    bmBurgerBars: {
        background: theme.linkColor
    },
    bmBurgerBarsHover: {
        background: theme.linkColorHover
    },
    bmCrossButton: {
        height: '24px',
        width: '24px'
    },
    bmCross: {
        background: '#bdc3c7'
    },
    bmMenuWrap: {
        position: 'fixed',
        height: '100%',
    },
    bmMenu: {
        background: theme.lightGray,
        padding: '2rem 0 0 0',
        fontSize: '0.8rem'
    },
    bmMorphShape: {
        fill: '#373a47'
    },
    bmItem: {
        display: 'inline-block'
    },
    bmOverlay: {
        background: 'rgba(0, 0, 0, 0.3)'
    }
}
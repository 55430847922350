import styled from '@emotion/styled/macro';

export const Section= styled.section`
  background-size: cover;
  background-position: left top;
  background-repeat: no-repeat;
  min-height: 100vh;
  padding: 80px 0 ;
  box-sizing: border-box;
  img {
      display: none;
  }
`;
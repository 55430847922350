import React from 'react';
import {Col, Row} from "react-flexbox-grid";
import ContentSection from '../components/ContentSection/ContentSection';

function NotFound(props){
    return (
        <ContentSection imgName="OldBus.jpg">
            <Row center="xs">
                <Col xs={11} md={8}>
                    <div className="defaultText">
                        <h1>Ups... </h1>
                        <p>Hier wolltest du bestimmt nicht hin.</p>

                        <p>Du willst lieber mehr über mich erfahren? Dann schau <a href="https://www.kaiserkoenig.de">hier</a> vorbei.
                        </p>
                        <p>Eher so auf SocialMedia? Kein Problem!<br/>
                            <a href="https://xing.com/profile/Timo_Koenig2">Visit me on XING</a><br />
                                <a href="https://www.linkedin.com/in/timo-koenig-7b28b144/">Visit me on LinkedIn</a><br />
                                <a href="https://twitter.com/derkaiserkoenig">Follow me on twitter</a><br />
                                <a href="mailto:koenig@kaiserkoenig.de">Mail me</a><br />
                        </p>

                    </div>
                </Col>
            </Row>
        </ContentSection>
    )
}

export default NotFound;
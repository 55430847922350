import React from 'react';
import 'normalize.css';
import './App.css';
import {BrowserRouter as Router, NavLink, Link, Switch} from "react-router-dom";
import Route from 'react-router-dom/Route';
import {push as Menu} from 'react-burger-menu';
import {theme} from './styledComponents';

import Home from './pages/Home';
// import About from './pages/About';
// import BusOffice from './pages/BusOffice';
import Imprint from './pages/Imprint';
import Legal from './pages/Legal';
import NotFound from './pages/NotFound';
import {styles} from './styledComponents';


class App extends React.Component {

    constructor (props) {
        super(props)
        this.state = {
            menuOpen: false
        }
    }

    // This keeps your state in sync with the opening/closing of the menu
    // via the default means, e.g. clicking the X, pressing the ESC key etc.
    handleStateChange (state) {
        this.setState({menuOpen: state.isOpen})
    }
    // This can be used to close the menu, e.g. when a user clicks a menu item
    closeMenu = () => {
        this.setState({menuOpen: false});
        console.log('close');
    }

    render() {
        return (
            <Router>
                <div className="App">
                    <div id="outer-container">
                        <main id="page-wrap" className="page-wrap">
                            <Switch>
                                <Route path="/" exact component={Home}/>
                                { /*<Route path="/about" exact component={About} />
                            <Route path="/busoffice" exact component={BusOffice} />*/}
                                <Route path="/imprint/" exact component={Imprint}/>
                                <Route path="/legal/" exact component={Legal}/>
                                <Route component={NotFound}/>
                            </Switch>
                        </main>
                    </div>
                    <header className="layer">
                        <Menu
                            isOpen={this.state.menuOpen}
                            onStateChange={(state) => this.handleStateChange(state)}
                            width={120}
                            styles={styles}
                            pageWrapId={"page-wrap"}
                            outerContainerId={"outer-container"}
                        >
                            <ul>
                                <li><NavLink to="/" exact onClick={this.closeMenu}>Home</NavLink></li>
                                {/* <li><NavLink to="/about" exact>Der Koenig</NavLink></li>
                            <li><NavLink to="/busoffice" exact>BusOffice</NavLink></li> */}
                            </ul>
                            <ul>
                                <li><NavLink to="/imprint/" exact onClick={this.closeMenu}>Impressum</NavLink></li>
                                <li><NavLink to="/legal/" exact onClick={this.closeMenu}    >Datenschutz</NavLink></li>
                            </ul>
                        </Menu>
                        <Link to="/" exact className="logoLink">
                            <svg className="logo" viewBox="0 0 389 161" version="1.1">
                                <g transform="matrix(1,0,0,1,-86,-77)">
                                    <path d="M87,203.7L87,208.6L90.5,210L116.4,212.1L135.3,212.1C138.1,225.4
                                150.7,235.9 164.7,235.9C179.4,235.9 192,225.4 194.8,211.4L373.4,211.4C376.2,225.4
                                388.1,235.9 403.5,235.9C418.9,235.9 430.8,225.4
                                433.6,210.7L455.3,210L467.2,205.1L472.8,200.9L472.8,172.2L471.4,169.4L468.6,167.3L470,165.9L470,160.3L468.6,155.4C468.6,155.4 466.5,148.4 458.8,144.2C451.8,140 419.6,128.8 419.6,128.8C419.6,128.8
                                 418.2,128.1 416.8,127.4C416.1,126.7 413.3,126.7 413.3,126.7L413.3,126L408.4,125.3C408.4,125.3 386,106.4
                                 357.3,88.2L356.6,88.2C355.9,87.5 355.2,86.8 354.5,86.8C354.5,86.8 349.6,83.3 344,81.9C341.9,81.9 339.1,81.2
                                 337,81.2L330,81.2C330,81.2 328.6,80.5 322.3,79.8L306.9,79.8L241.1,79.1L163.4,79.8C163.4,79.8 133.3,80.5
                                 121.4,81.9L110.2,83.3L108.8,86.1L109.5,86.8C109.5,86.8 103.2,106.4 98.3,134.4L97.6,135.1L96.9,149.8L96.9,169.4L95.5,171.5L95.5,188.3L96.2,191.1L96.9,192.5L91.3,197.4"
                                          style={{fill: theme.linkColor}}
                                    />
                                </g>
                            </svg>
                        </Link>
                    </header>
                </div>
            </Router>


        )
    };
}

export default App;

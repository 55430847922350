import React from 'react';
import { Grid } from "react-flexbox-grid";
import {Section} from './styledComponents'

const DEFAULT_DELAY = 1250;           // Delay zwischen den Aufrufen
let throttled = false;

class ContentSection extends React.Component{

    constructor(props) {
        super(props);
        this.element = React.createRef();
        this.img = React.createRef();
        // this.img =  this.element.current.querySelector('img');
        this.src = '';

    }

    componentDidMount() {
        if(this.props.imgName) {
            const element = this;
            element.img.current.addEventListener('load', () => {
                element.update();
            });

            if (element.img.current.complete) {
                element.update();
            }
            // Entdeckt, ob das Gerät das orientationchange-Event unterstützt
            // Fallback auf resize, falls nicht
            let supportsOrientationChange = "onorientationchange" in window,
                orientationEvent = supportsOrientationChange ? "orientationchange" : "resize";

            window.addEventListener(orientationEvent, function () {
                if (!throttled) {      // nur wenn throttled nicht gesetzt ist
                    element.update();

                    throttled = true;    // Jetzt wird wieder ausgebremst

                    setTimeout(function () {  // Timeout
                        throttled = false;
                    }, DEFAULT_DELAY);
                }


            }, false);
        }
    }

    update = () => {

        let src = typeof this.img.current.currentSrc !== 'undefined' ? this.img.current.currentSrc : this.img.current.src;
        if (this.src !== src) {
            this.src = src;
            this.element.current.style.backgroundImage = 'url("' + this.src + '")';
        }
    };

    render() {
        const {
            children,
            imgName,
        } = this.props;

        const imgL = imgName ? require(`../../img/l/${imgName}`) : require(`../../img/l/Island.jpg`);
        const imgS = imgName ? require(`../../img/s/${imgName}`) : require(`../../img/s/Island.jpg`);
        const imgM = imgName ? require(`../../img/m/${imgName}`) : require(`../../img/m/Island.jpg`);

        return (
            <Section ref={this.element}>
                <img ref={this.img}
                     alt={`background ${imgName}`}
                     srcSet={`
                          ${imgS} 375w,
                          ${imgM} 1375w,
                          ${imgL} 1920w
                          `}
                     sizes="100vw"
                />
                <Grid>{children}</Grid>
            </Section>
        )
    };
}

export default ContentSection;
import React, {Fragment} from 'react';
import {Row, Col} from 'react-flexbox-grid';
import ContentSection from "../components/ContentSection/ContentSection";


class Home extends React.Component {
    handleScroll = () =>{
        let target = document.querySelectorAll('a[href^="#"]');
        let i = 0;
        for (i=0; i<target.length; i++) {
            target[i].onclick = function (e) {
                let hash = this.getAttribute("href");
                console.log(hash);
                let elem = document.getElementById(hash.replace("#",""));
                //history.pushState (null, null, hash);
                elem.scrollIntoView({ left: 0, block: 'start', behavior: 'smooth' });
                e.preventDefault();
            }
        }
    }


    render() {
        return (
            <Fragment>
                <ContentSection imgName="OldBus.jpg">
                    <Row center="xs">
                        <Col xs={11} md={8}>
                            <div className="defaultText">
                                <h1>#busOffice </h1>
                                <h2>Imagine you could have the world's best employees...</h2>
                                <h2 className="text-right">... but they don't want to work in your office!</h2>
                                <a href="#contactME" className="button">Mach Mit</a>
                            </div>
                        </Col>
                    </Row>
                </ContentSection>
                <ContentSection imgName="BusInside.jpg">
                    <Row center="xs">
                        <Col xs={11} md={8}>
                            <div className="defaultText">

                            </div>
                        </Col>
                    </Row>
                    <Row center="xs">
                        <Col xs={11} md={4}>
                            <div className="defaultText">
                                <h2>Remote arbeiten</h2>
                                <p>
                                    Seit vielen Jahren arbeite ich remote in Projekten aller Größenordnungen.<br/>
                                    Vor 3 Jahren fing ich an, zwischen Terminen schöne Plätze
                                    zum Arbeiten in meinem Bus zu suchen. <br/>
                                    Auch als Alternative zu stickigen oder überfüllten Büros bietet er bei jedem
                                    Wetter alles, was man braucht. Schöne Aussicht inklusive!


                                </p>

                            </div>
                        </Col>
                        <Col xs={11} md={4} mdOffset={2}>
                            <div className="defaultText">
                                <h2>Wo man es mag</h2>
                                <p>
                                    Im Juni 2019 kam mir die Idee, nicht weiter alleine im Bus zu sitzen,
                                    sondern alte und neue Bekannte einzuladen, um 1-2 Stunden gemeinsam zu arbeiten oder
                                    über aktuelle Themen zu diskutieren. Was als fixe Idee mit einem Post in den
                                    sozialen Netzen
                                    begann, ist mittlerweile ein fester Termin in meinem
                                    Kalender.
                                    Damit hatte ich nicht gerechnet. <a href="#contactME" className="button">Mach Mit</a>
                                </p>

                            </div>
                        </Col>
                    </Row>
                </ContentSection>
                <ContentSection imgName="BusBack.jpg">
                    <Row center="xs">
                        <Col xs={11} md={4}>
                            <div className="defaultText">
                                <h2>Du willst auch?</h2>
                                <p>Egal ob wir uns kennen oder noch nicht.
                                    Meld dich bei mir und wir arbeiten zusammen oder diskutieren.
                                </p>
                                <p>Ich hätte vorher nur gerne ein Gesicht gesehen :O)</p>
                            </div>
                        </Col>
                        <Col xs={11} md={4} mdOffset={2} >
                            <section className="defaultText" id="contactME">
                                <h2>Mach mit</h2>
                                <p>
                                    <a href="https://xing.com/profile/Timo_Koenig2" target="_blank"
                                       rel="noopener noreferrer">Visit me on XING</a><br/>
                                    <a href="https://www.linkedin.com/in/timo-koenig-7b28b144/" target="_blank"
                                       rel="noopener noreferrer">Visit me on LinkedIn</a><br/>
                                    <a href="https://twitter.com/derkaiserkoenig" target="_blank"
                                       rel="noopener noreferrer">Follow me on twitter</a><br/>
                                    <a href="mailto:koenig@kaiserkoenig.de" target="_blank" rel="noopener noreferrer">Mail
                                        me</a><br/>
                                </p>
                            </section>
                        </Col>
                    </Row>
                </ContentSection>
            </Fragment>
        )
    }
}

export default Home;

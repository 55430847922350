import React from 'react';

import {Col, Row} from "react-flexbox-grid";
import ContentSection from "../components/ContentSection/ContentSection";

function Imprint(props) {
    return (
        <ContentSection imgName="OldBus.jpg">
            <Row center="xs">
                <Col xs={11} md={8}>
                    <div className="defaultText">
                        <h1 id="imprint">Angaben gemäß § 5 TMG</h1>
                        <p>KaiserKoenig GmbH
                            <br />Sitz der Gesellschaft:<br/>
                                Wikingerweg 14<br/>21493 Schwarzenbek<br/><br/>
                                Registergericht: Lübeck, HRB 20395 HL<br/>
                                USt-Id: DE334449370<br/><br/>
                                Geschäftsführer: Timo Koenig<br/><br/>

                                <a href="mailto:koenig@kaiserkoenig.de">kontakt@kaiserkoenig.de</a><br/>
                                <a href="www.kaiserkoenig.de">www.kaiserkoenig.de</a><br/><br/>
                                Telefon: +49 (0)176 493 22 8 55<br/><br/>
                                </p>

                        <h3>© Copyright 2021</h3>
                        <p>
                            Alle Inhalte, insbesondere Texte, Fotografien und Grafiken sind urheberrechtlich geschützt.
                            Alle Rechte, einschließlich der Vervielfältigung, Veröffentlichung, Bearbeitung und Übersetzung,
                            bleiben vorbehalten, [Timo Koenig].
                        </p>
                        <p>
                            Sollten sich Personen oder Gegenstände auf den verwendteten Bildern befinden, die dies ausdrücklich nicht
                            wünschen, bitte ich um eine kurze Mitteilung.
                        </p>


                        <h3>Online-Streitbeilegung</h3>
                        <p>
                            Die Europäische Kommission stellt unter <a
                            href="https://ec.europa.eu/consumers/odr/">https://ec.europa.eu/consumers/odr/</a>
                            eine Plattform zur Online-Streitbeilegung bereit, die Verbraucher für die Beilegung einer
                            Streitigkeit nutzen können und auf der weitere Informationen zum Thema Streitschlichtung zu
                            finden sind.
                        </p>
                        <h3>Außergerichtliche Streitbeilegung</h3>
                        <p>
                            Wir sind weder verpflichtet noch dazu bereit, im Falle einer Streitigkeit mit einem
                            Verbraucher an einem Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle
                            teilzunehmen.
                        </p>

                    </div>
                </Col>
            </Row>
        </ContentSection>
    )
}

export default Imprint;
